<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Pedidos B2B</h4>
        </div>

        <b-card-text class="d-flex flex-column">
          <b-table
            :items="formatedOrder"
            :fields="listOrderFields"
            bordered
            striped
            hover
            @row-clicked="changeRoute"
          >
            <template #cell(actions)="row">
              <b-button
                class="mr-2"
                size="sm"
                variant="primary"
                @click="changeRoute(row.item)"
              >
                <b-icon icon="eye-fill"></b-icon>
              </b-button>

              <b-button
                variant="primary"
                size="sm"
                class="mr-2"
                title="Imprimir pedido"
                @click="getOrderPDFToPrint(row.item.orderId)"
              >
                <b-icon icon="printer" />
              </b-button>

              <b-button
                variant="primary"
                size="sm"
                class="mr-2"
                title="Enviar mensagem"
                @click="openSendEmailModal(row.item)"
              >
                <b-icon icon="envelope"
              /></b-button>

              <upload-xml-pdf-order-b2b
                v-if="
                  row.item.statusPanel.description === 'Pagamento Confirmado'
                "
                :order-id="row.item.orderId"
                @status="status"
              />

              <b-button
                v-if="row.item.statusPanel.description === 'Pedido Realizado'"
                variant="success"
                size="sm"
                title="Confirmar pagamento"
                @click="openConfirmPaymentModal(row.item.orderId)"
              >
                <b-icon icon="check-circle"></b-icon
              ></b-button>

              <b-button
                v-if="row.item.statusPanel.description === 'Pedido Realizado'"
                size="sm"
                title="Cancelar pagamento"
                class="ml-2"
                @click="openCancelPaymentModal(row.item.orderId)"
              >
                <b-icon icon="x-circle"></b-icon>
              </b-button>
            </template>
          </b-table>

          <b-modal
            v-model="sendEmailModal"
            :title="sendEmailForm.title"
            size="lg"
            hide-footer
          >
            <b-form @submit.prevent="sendEmail">
              <form-input
                v-model="sendEmailForm.subject"
                label="Assunto:"
                placeholder="Assunto do email"
                icon="envelope"
                :errors="errors['subject']"
                spellcheck
                required
              />

              <form-textarea
                v-model="sendEmailForm.message"
                label="Mensagem:"
                placeholder="Conteúdo da mensagem"
                icon="chat-left-text"
                :errors="errors['message']"
                spellcheck
                required
              />

              <b-button type="submit">Enviar</b-button>
            </b-form>
          </b-modal>

          <b-modal
            v-model="confirmPaymentModal"
            title="Confirmar pagamento"
            hide-footer
          >
            <b-row> <b-col>Deseja confirmar o pagamento?</b-col> </b-row>
            <div class="d-flex justify-content-end">
              <b-button
                class="mt-4"
                variant="success"
                @click="confirmPaymentOrder"
                >CONFIRMAR</b-button
              >
            </div>
          </b-modal>

          <b-modal
            v-model="cancelPaymentModal"
            title="Cancelar pagamento"
            hide-footer
          >
            <b-row> <b-col>Deseja cancelar o pagamento?</b-col> </b-row>
            <div class="d-flex justify-content-end">
              <b-button class="mt-4" @click="cancelPaymentOrder"
                >CONFIRMAR</b-button
              >
            </div>
          </b-modal>
        </b-card-text>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";

import UploadXmlPdfOrderB2b from "../../components/orderB2b/UploadXmlPdfOrderB2b.vue";
import FormInput from "../../components/form/FormInput.vue";
import FormTextarea from "../../components/form/FormTextarea.vue";

export default {
  components: {
    UploadXmlPdfOrderB2b,
    FormInput,
    FormTextarea,
  },
  data() {
    return {
      allOrder: [],
      sendEmailModal: false,
      confirmPaymentModal: false,
      cancelPaymentModal: false,
      orderId: 0,

      listOrderFields: [
        {
          key: "orderId",
          label: "N° Pedido",
          sortable: true,
        },
        {
          key: "statusPanel.description",
          label: "Situação",
          sortable: true,
        },
        {
          key: "client.name",
          label: "Cliente",
          sortable: true,
        },
        {
          key: "city",
          label: "Cidade",
          sortable: true,
        },
        {
          key: "state",
          label: "UF",
          sortable: true,
        },
        {
          key: "paymentMethod",
          label: "Forma de Pagamento",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Data/Hora",
        },
        {
          key: "actions",
          label: "Ações",
        },
      ],
      sendEmailForm: {
        subject: "",
        message: "",
        title: "",
      },
      errors: {},
    };
  },
  computed: {
    formatedOrder() {
      return this.allOrder.map((value) => {
        return {
          ...value,
          createdAt: new Date(value.createdAt).toLocaleString("pt-BR"),
        };
      });
    },
  },
  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess", "setOverlay"]),

    status(item) {
      const order = this.allOrder.find(
        (element) => element.orderId === item.id
      );

      order.statusPanel.description = item.status;
    },

    openSendEmailModal(item) {
      this.sendEmailModal = true;
      const {
        client: { name },
        orderId,
      } = item;
      this.orderId = orderId;
      this.sendEmailForm.subject = `${name} vamos lhe atualizar sobre o pedido ${orderId}`;
      this.sendEmailForm.message = "";
      this.sendEmailForm.title = `Enviar e-mail ao cliente ${name}`;
    },

    openConfirmPaymentModal(id) {
      this.orderId = id;
      this.confirmPaymentModal = true;
    },

    openCancelPaymentModal(id) {
      this.orderId = id;
      this.cancelPaymentModal = true;
    },

    async sendEmail() {
      try {
        await http.post(`/b2b/sendEmail/${this.orderId}`, {
          subject: this.sendEmailForm.subject,
          message: this.sendEmailForm.message,
        });
        this.setToastedSuccess({ message: "Mensagem enviada com sucesso!" });
        this.sendEmailModal = false;
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message:
              "Erro ao enviar email para cliente, tente novamente mais tarde",
          });
        }
      }
    },
    async getOrderPDFToPrint(orderId) {
      try {
        this.setOverlay(true);
        const url = `/b2b/orderPDF/${orderId}`;
        const { data } = await http.get(url, { responseType: "blob" });

        const pdfBlob = URL.createObjectURL(data);
        window.open(pdfBlob, "_blank");

        this.setToastedSuccess({ message: "PDF gerado com sucesso" });
      } catch (err) {
        this.setToastedError({ message: "Erro ao PDF para impressão" });
      }
      this.setOverlay(false);
    },

    async confirmPaymentOrder() {
      try {
        this.setOverlay(true);
        this.confirmPaymentModal = false;
        const { data } = await http.put(`/b2b/confirmPayment/${this.orderId}`);

        const index = this.allOrder.findIndex(
          (element) => element.orderId === data.orderId
        );
        this.allOrder.splice(index, 1, data);
        this.setToastedSuccess({ message: "Pedido confirmado" });
      } catch (error) {
        this.setToastedError({
          message: "Erro ao confirmar pagamento",
        });
      }
      this.setOverlay(false);
    },

    async cancelPaymentOrder() {
      try {
        this.setOverlay(true);

        this.cancelPaymentModal = false;
        const { data } = await http.put(`/b2b/cancelPayment/${this.orderId}`);
        const index = this.allOrder.findIndex(
          (element) => element.orderId === data.orderId
        );
        this.allOrder.splice(index, 1, data);
        this.setToastedSuccess({ message: "Pedido cancelado" });
      } catch (error) {
        this.setToastedError({
          message: "Erro ao cancelar pagamento",
        });
      }
      this.setOverlay(false);
    },

    async changeRoute(item) {
      const { orderId } = item;
      this.$router.push(`pedidosb2b/${orderId}/editar`);
    },
  },
  async created() {
    this.setOverlay(true);
    const { data } = await http(`/b2b/orders`);
    this.allOrder = data;
    this.setOverlay(false);
  },
};
</script>
